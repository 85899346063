.dashboard {
    padding: 2rem;
    text-align: center;
    background-color: #f8f9fa;
  }
  
  .dashboard h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .dashboard p {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 2rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .card-container {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
  }
  
  .card {
    width: 220px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  
  .card img {
    width: 80%;
    max-height: 120px;
    object-fit: contain;
    margin-bottom: 1rem;
    display: block;
  }
  
  .card h2 {
    font-size: 1.3rem;
    color: #333;
    margin: 0.5rem 0;
  }
  
  .card p {
    font-size: 0.95rem;
    color: #666;
    margin: 0 1rem;
  }
  