.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #007bff;
    color: white;
  }
  
  .navbar a {
    color: white;
    text-decoration: none;
    margin: 0 1rem;
    font-size: 1rem;
  }
  
  .navbar a:hover {
    text-decoration: underline;
  }
  
  .logout-button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .logout-button:hover {
    background-color: #cc0000;
  }
  